import http from "@/utils/request";
// 根据id查询详情

export function findDetail(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findDetail.nd",
        params:data,
    });
}

import { findDetail } from "@/views/boothStoreExpenses/boothStoreView/api";
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/boothStoreExpenses",
          name: "agentVerificationActivities",
          title: "展台需求提报"
        },
        {
          path: "/agentVerificationActivities/",
          name: "activityView",
          title: "查看"
        }
      ],
      pageLoadFlag: false,
      detilInfo: {},
      tableData: [],
      shopCostManageDtos: [], // 立项单
      fileList: [],
      fileListJS: [],
      previewVisible1: false,
      previewVisible: false,
      previewImage: "",

      columns: [
        {
          title: "需求子单",
          dataIndex: "type",
          width: 250
        },

        {
          title: "门店*",
          dataIndex: "shopName",
          width: 200

        },
        {
          title: "是否专卖店*",
          dataIndex: "isSpecialShopText",
          width: 120
        },
        {
          title: "物料组*",
          dataIndex: "baseMatklName",
          width: 120

        },
        {
          title: "分销网络规模(万元)",
          dataIndex: "FXnet",
          width: 180

        },

        {
          title: "所属办事处*",
          dataIndex: "office",
          width: 220

        },

        {
          title: "门店级别*",
          dataIndex: "shopLevelName",
          width: 220

        },
        {
          title: "门店展厅面积(㎡)*",
          dataIndex: "shopArea",
          width: 220

        },
        {
          title: "展厅延米*",
          dataIndex: "shopLinearMeter",
          width: 220

        },
        // {
        //   title: "厅型*",
        //   dataIndex: "shapeText",
        //   width: 220

        // },
        // {
        //   title: "顶材质*",
        //   dataIndex: "topMaterialText",
        //   width: 220

        // },
        // {
        //   title: "地材质*",
        //   dataIndex: "groundMaterialText",
        //   width: 220

        // },

        {
          title: "需求类型*",
          dataIndex: "supplyType.propertyName",
          width: 110

        },
        {
          title: "是否品牌园*",
          dataIndex: "isBuildBrandParkText",
          width: 120
        },
        {
          title: "派单类型*",
          dataIndex: "isHeaderUnionText",
          width: 160

        },
        {
          title: "供应商",
          dataIndex: "SupplierName",
          width: 300

        },
        {
          title: "费用预估(元)",
          dataIndex: "costEstimate",
          width: 200

        },
        {
          title: "要求完成时间",
          dataIndex: "completionTime",
          width: 200

        },
        {
          title: "进场起一年内目标销量(台)",
          dataIndex: "saleT",
          width: 230

        },
        {
          title: "进场起一年内目标销售额(万元)",
          dataIndex: "salesVolumes",
          width: 230

        },

        {
          title: "说明*",
          dataIndex: "remark",
          width: 400
        },
        {
          title: "展台使用期限(年)",
          dataIndex: "curStartYear",
          width: 400
        }

      ]
    };
  },


  mounted() {
    if (this.$route.query.id) {
      this.getSignleInfo(this.$route.query.id);
    } else {
      this.$message.warning("获取详情失败");
    }
  },

  methods: {
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    async handlePreview1(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    // 查询详情
    getSignleInfo(id) {
      this.pageLoadFlag = true
      let data = {
        id: id
      };
      findDetail(data).then(res => {
        if (res.data.code == 0) {
          this.detilInfo = res.data.data
          this.fileList = res.data.data.attachs;
          this.tableData = res.data.data.supplyDetailList;
          this.shopCostManageDtos = res.data.data.shopCostManageDtos;
          // if (res.data.data.attachs && res.data.data.attachs.length > 0) {
          //   res.data.data.attachs.forEach(item => {
          //     this.fileList.push(
          //       {
          //         uid: item.id,
          //         name: item.attachName,
          //         status: "done",
          //         url: item.attachPath
          //       }
          //     );
          //   });
          // }
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => {
              item.fetching2 = false;
              item.fetching = false;
              item.type = item.supplyDetailCode;
              item.shopId = item.custShopInfoDto&&item.custShopInfoDto.id?String(item.custShopInfoDto.id):'';
              item.shopName = item.custShopInfoDto&&item.custShopInfoDto.fullName?item.custShopInfoDto.fullName:'';
              item.isSpecialShopText = item.custShopInfoDto&&item.custShopInfoDto.isSpecialShopText? item.custShopInfoDto.isSpecialShopText:'';
              item.isSpecialShop = item.custShopInfoDto&&item.custShopInfoDto.isSpecialShop?item.custShopInfoDto.isSpecialShop:'';
              item.supplyTypeId =item.supplyType&&item.supplyType.id? String(item.supplyType.id):'';
              item.isHeaderUnion = item.isHeaderUnion?String(item.isHeaderUnion):'';
              item.SupplierId = item.supplierCode;
              item.SupplierName = item.supplierName;
              item.costEstimate = item.estimatedCost;
              item.completionTime = item.finishDate;
              item.saleT = item.targetNumber;
              item.salesVolumes = item.targetMoney;
              item.office = item.custShopInfoDetailDto?item.custShopInfoDetailDto.orgName:''
              item.officeId = item.custShopInfoDetailDto?item.custShopInfoDetailDto.orgId:''
              item.baseMatklId = item.custShopInfoDetailDto?item.custShopInfoDetailDto.baseMatklId:''
              item.baseMatklName = item.custShopInfoDetailDto?item.custShopInfoDetailDto.baseMatklName:''
              // 门店级别
              item.shopLevelName = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.shopLevelName:''
              item.supplyTypeName = item.supplyType?item.supplyType.propertyName:''
              // 分销网络规模
              item.FXnet = item.annualSalesScale?item.annualSalesScale:'';
              item.curStartYear = item.boothServiceLife?item.boothServiceLife:''


            });
          }
          this.pageLoadFlag = false
        }else {
          this.pageLoadFlag = false
        }
      });
    },
    downLoad(item){
      window.open(item.attachPath)
    },

    goback() {
      window.close();

    }
  }
};